import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import ReactPaginate from "react-paginate";
import { useParams } from "react-router-dom";
import UserReviewComponent from "../../components/UserReviewComponent";
import UserprofileDiv from "../../components/UserprofileDiv";
import "../../styles/bootstrap.css";
import "../../styles/flaticon_veritatrust.css";
import "../../styles/style.css";
import { useAxios } from "../../utils/hook/useAxios";

function Items({ currentItems }) {
  return (
    <>
      {currentItems &&
        currentItems
          .filter((review) => review.statu === "published")
          .map((review, index) => (
            <div>
              <UserReviewComponent key={index} data={review} />
            </div>
          ))}
    </>
  );
}

function PaginationComponent({ itemsPerPage, items }) {
  // Here we use item offsets; we could also use page offsets
  // following the API or data you're working with.
  const [itemOffset, setItemOffset] = useState(0);
  const [currentPage, setCurrentPage] = useState(0);

  // Simulate fetching items from another resources.
  // (This could be items from props; or items loaded in a local state
  // from an API endpoint with useEffect and useState)
  const endOffset = itemOffset + itemsPerPage;
  // console.log(`Loading items from ${itemOffset} to ${endOffset}`);
  const currentItems = items.slice(itemOffset, endOffset);
  const pageCount = Math.ceil(items.length / itemsPerPage);
  // Invoke when user click to request another page.
  const handlePageClick = (event) => {
    const newOffset = (event.selected * itemsPerPage) % items.length;
    // console.log(
    //   `User requested page number ${event.selected}, which is offset ${newOffset}`
    // );

    // console.log(currentItems);
    setItemOffset(newOffset);
    const selectedPage = event.selected;
    setCurrentPage(selectedPage);
    const queryParams = new URLSearchParams(window.location.search);

    queryParams.set("page", selectedPage + 1);
    const newUrl = window.location.pathname + "?" + queryParams.toString();
    window.history.pushState({}, "", newUrl);
  };

  return (
    <>
      <Items currentItems={currentItems} />
      <nav className="mt-5">
        <ReactPaginate
          breakLabel="..."
          nextLabel=">"
          onPageChange={handlePageClick}
          marginPagesDisplayed={1}
          pageRangeDisplayed={2}
          pageCount={pageCount}
          previousLabel="<"
          renderOnZeroPageCount={null}
          containerClassName="pagination pagination-lg justify-content-center"
          pageClassName="page-item"
          pageLinkClassName="page-link"
          nextClassName="page-item"
          nextLinkClassName="page-link"
          previousClassName="page-item"
          previousLinkClassName="page-link"
          disabledLinkClassName="disabled"
          activeLinkClassName="active"
          breakClassName="page-item"
          breakLinkClassName="page-link"
          forcePage={currentPage}
        />
      </nav>
    </>
  );
}

function UserprofilePage() {
  const axios = useAxios();

  let { id } = useParams();

  const [user, setUser] = useState({});
  const [userReviews, setUserReviews] = useState([]);
  const [LastUserReviews, setLastUserReviews] = useState([]);

  const { t } = useTranslation();
  const { i18n } = useTranslation();
  const { lang } = useParams();

  useEffect(() => {
    i18n.changeLanguage(lang);

    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    let fetchReviews = async () => {
      const response = await axios.get("/users/" + id);
      const userReviewResponse = await axios.get("/lreview-dashboard/" + id);
      setUser(response.data);
      setUserReviews(userReviewResponse.data);
      setLastUserReviews(userReviewResponse.data);
    };
    fetchReviews();
  }, []);

  const handleProductReview = async () => {
    setLastUserReviews(userReviews);
    setUserReviews(
      LastUserReviews.filter(
        (review) => review.review_type === "product_review"
      )
    );
  };

  const handleMerchantReview = async () => {
    setLastUserReviews(userReviews);
    setUserReviews(
      LastUserReviews.filter(
        (review) => review.review_type === "merchant_review"
      )
    );
  };

  const handleAllReviews = async () => {
    let fetchReviews = async () => {
      const response = await axios.get("/users/" + id);
      const userReviewResponse = await axios.get("/lreview-dashboard/" + id);
      setUser(response.data);
      setUserReviews(userReviewResponse.data);
      setLastUserReviews(userReviewResponse.data);
    };
    fetchReviews();
  };

  return (
    <>
      <UserprofileDiv data={user}></UserprofileDiv>
      <section id="products" className="user_reviews bg-success pb-5">
        <div className="container">
          <div className="row">
            <div className="col-12 col-md-12 py-4 mx-auto">
              <div class="review-filter d-flex justify-content-center gap-3 mt-3">
                <input
                  type="radio"
                  class="btn-check"
                  name="reviews-filter"
                  id="all-reviews"
                  autocomplete="off"
                  defaultChecked
                />
                <label
                  class="btn btn-outline-light rounded-5"
                  for="all-reviews"
                  onClick={handleAllReviews}
                >
                  {t("AccountReviewsTitle")}
                </label>
                <input
                  type="radio"
                  class="btn-check"
                  name="reviews-filter"
                  id="product-reviews"
                  autocomplete="off"
                />
                <label
                  class="btn btn-outline-light rounded-5"
                  for="product-reviews"
                  onClick={handleProductReview}
                >
                  {t("UserprofileProductReviews")}
                </label>
                <input
                  type="radio"
                  class="btn-check"
                  name="reviews-filter"
                  id="merchant-reviews"
                  autocomplete="off"
                />
                <label
                  class="btn btn-outline-light rounded-5"
                  for="merchant-reviews"
                  onClick={handleMerchantReview}
                >
                  {t("UserprofileMerchantReviews")}
                </label>
              </div>
            </div>
            <div className="col-11 col-md-12 col-lg-8 pb-4 mx-auto">
              <div>
                <PaginationComponent
                  itemsPerPage={20}
                  items={userReviews.filter(
                    (review) => review.statu === "published"
                  )}
                ></PaginationComponent>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default UserprofilePage;
