import axios from "axios";
import React, { createContext, useContext } from "react";

const axiosContext = createContext();
const baseUrl = "https://api.veritatrust.com/v1";
//const baseUrl = "http://localhost:4000/api";
export function ProvideAxios({ children }) {
  const axios = useProvideAxios();
  return (
    <axiosContext.Provider value={axios}>{children}</axiosContext.Provider>
  );
}

export const useAxios = () => {
  return useContext(axiosContext);
};

function useProvideAxios() {
  function getCookie(name) {
    const value = `; ${document.cookie}`;
    const parts = value.split(`; ${name}=`);
    if (parts.length === 2) return parts.pop().split(";").shift();
  }
  const setHeader = () => {
    axios.defaults.headers.common["Accept"] = "/";
    axios.defaults.headers.common["Content-Encoding"] = "application/json";
    axios.defaults.headers.common["Content-Type"] = "application/json";
  };
  const authToken = getCookie("jwtToken");

  const config = {
    headers: {
      Authorization: `Bearer ${authToken}`, // Utilisation de Bearer pour les jetons JWT
      // Si vous utilisez un autre type d'autorisation, ajustez cette ligne en conséquence
    },
  };

  const post = async (url, data) => {
    //  setHeader();

    try {
      return await axios.post(baseUrl + url, data, config);
    } catch (error) {
      // console.log(error);
    }
  };

  const get = async (url) => {
    //    setHeader();
    try {
      return await axios.get(baseUrl + url, config);
    } catch (error) {
      // console.log(error);
    }
  };

  const put = async (url, data) => {
    //    setHeader();
    try {
      return await axios.put(baseUrl + url, data, config);
    } catch (error) {
      // console.log(error);
    }
  };

  return {
    post,
    get,
    put,
  };
}
